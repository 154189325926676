import Masonry from 'masonry-layout';

const initMasonry = () => {
    if (window) {
        setTimeout( ()=> { 
            new Masonry( '.grid', {
                itemSelector: '.grid-item',
                gutter: 20    
            });    
        },200)
    }
};

const onRouteUpdate = () => {
    initMasonry();  
};

export {onRouteUpdate}