module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Objav","short_name":"Objav","start_url":"/","background_color":"#664EA3","theme_color":"#664EA3","display":"standalone","icon":"src/images/icon.svg","crossOrigin":"use-credentials","theme_color_in_head":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-fathom/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"MNUBARDP"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
